import uuid from 'uuid';

function itemsByGUID(items) {
  const byGUID = {};
  for (let item of items) {
    byGUID[item.fdGUID] = item;
  }
  return byGUID;
}

export default function createAjaxReducer(name) {
  return function(
    state = {
      items: [],
      byGUID: {},
    },
    action,
  ) {
    switch (action.type) {
      case `FETCH_${name}_REQUEST`:
        if (action.blank === true) {
          return { ...state, items: [], byGUID: {}, status: 'FETCHING' };
        }
        return { ...state, status: 'FETCHING' };
      case `FETCH_${name}_ERROR`:
        return { ...state, status: 'ERROR' };
      case `FETCH_${name}_SUCCESS`: {
        if (
          name === 'PLUS' &&
          action.options.command === 'tyro.connect.menu' &&
          action.response.success
        ) {
          return {
            ...state,
            status: 'FETCHED',
          };
        }
        if (name === 'MASTERREPORTS' && !action.response.results) {
          return {
            ...state,
          };
        }
        const byGUID = {};
        let items = action.response.results;
        state.items.forEach((item, i) => {
          if (item.isNewRecord && i === 0) {
            items.unshift(item);
          } else if (item.isNewRecord) {
            items.push(item);
          }
        });
        if (name === 'PARAMETERS') {
          const byName = {};
          for (let item of items) {
            byName[item.fdName] = item;
          }
          return {
            ...state,
            items,
            byName,
            status: 'FETCHED',
            fields: action.response.fields,
          };
        }
        if (name === 'USERS') {
          return {
            ...state,
            items,
            fields: action.response.fields,
            status: 'FETCHED',
          };
        }
        if (
          name === 'POSLAYOUTS' ||
          name === 'PAYMENTLAYOUTS' ||
          name === 'MODGROUPS' ||
          name === 'CUSTOMERDISPLAYLAYOUTS' ||
          name === 'COUPONLAYOUTS'
        ) {
          const venuesResults = action.response.venues_results;
          if (name === 'MODGROUPS') {
            for (let item of items) {
              byGUID[item.fdGUID] = item;
            }
            return {
              ...state,
              items,
              byGUID,
              venuesResults,
              fields: action.response.fields,
              status: 'FETCHED',
            };
          }
          return {
            ...state,
            items,
            venuesResults,
            fields: action.response.fields,
            status: 'FETCHED',
          };
        }
        for (let item of items) {
          byGUID[item.fdGUID] = item;
        }
        return {
          ...state,
          items,
          fields: action.response.fields,
          byGUID,
          status: 'FETCHED',
        };
      }
      case `INSERT_${name}_REQUEST`:
        return state;
      case `INSERT_${name}_ERROR`:
        alert(action.error);
        return state;
      case `INSERT_${name}_SUCCESS`: {
        if (name === 'USERS') {
          let idx = state.items.findIndex(function(entry) {
            return entry.fdUserID === action.data.fdUserID;
          });
          if (idx === -1) {
            idx = state.items.findIndex(function(entry) {
              return entry.fdGUID === action.data.fdGUID;
            });
          }
          let newItems = [...state.items];
          //destructure action.data to remove isNewRecord from the mix
          if (idx === -1) {
            const { isNewRecord, ...data } = action.data;
            const item = {
              ...data,
            };
            newItems.unshift(item);
          } else {
            let item = { ...newItems[idx], ...action.data };
            const { isNewRecord, ...myItem } = item;
            newItems[idx] = myItem;
          }
          return { ...state, items: newItems };
        }
        let idx = state.items.findIndex(function(entry) {
          return entry.fdGUID === action.data.fdGUID;
        });

        let newItems = [...state.items];
        //destructure action.data to remove isNewRecord from the mix
        if (idx === -1) {
          const { isNewRecord, ...data } = action.data;
          const item = {
            ...data,
            ...action.response.data,
            fdGUID: action.response.guid,
          };
          if (action.response.fields) {
            let item = {
              ...data,
              fdGUID: action.response.guid,
            };
            for (let field in action.response.fields) {
              item[field] = action.response.fields[field];
            }
          }
          if (action.options.addLast) {
            newItems.push(item);
          } else {
            newItems.unshift(item);
          }
        } else {
          let item;
          if (action.response.fields) {
            item = {
              ...newItems[idx],
              ...action.data,
              ...action.response.data,
              fdGUID: action.response.guid,
            };
            for (let field in action.response.fields) {
              item[field] = action.response.fields[field];
            }
          } else {
            item = {
              ...newItems[idx],
              ...action.data,
              ...action.response.data,
              fdGUID: action.response.guid,
            };
          }
          const { isNewRecord, ...myItem } = item;
          newItems[idx] = myItem;
        }
        if (name === 'PARAMETERS') {
          const byName = {};
          for (let item of newItems) {
            byName[item.fdName] = item;
          }
          return {
            ...state,
            items: newItems,
            byGUID: itemsByGUID(newItems),
            byName,
          };
        }
        return {
          ...state,
          items: newItems,
          byGUID: itemsByGUID(newItems),
        };
      }
      case `CREATE_LOCAL_${name}`: {
        if (action.addLast)
          return {
            ...state,
            items: [
              ...state.items,
              {
                fdGUID: uuid().toUpperCase(),
                ...action.data,
              },
            ],
          };
        return {
          ...state,
          items: [
            {
              fdGUID: uuid().toUpperCase(),
              ...action.data,
            },
            ...state.items,
          ],
        };
      }
      case `UPDATE_LOCAL_${name}`: {
        const newItems = [...state.items];
        let idx = newItems.findIndex(function(entry) {
          return entry.fdGUID === action.guid;
        });
        if (idx === -1) return state;
        if (action.obj) {
          newItems[idx] = {
            ...newItems[idx],
            ...action.obj,
          };
        } else {
          newItems[idx] = {
            ...newItems[idx],
            [action.field]: action.value,
          };
        }
        return { ...state, items: newItems };
      }
      case `DELETE_${name}_REQUEST`:
        return state;
      case `DELETE_${name}_ERROR`:
        console.log(action.error);
        return state;
      case `DELETE_${name}_SUCCESS`: {
        if (name === 'USERS') {
          let idx = state.items.findIndex(function(entry) {
            return entry.fdUserID === action.options.fdUserID;
          });
          if (idx === -1) return state;
          let items = [...state.items.slice(0, idx), ...state.items.slice(idx + 1)];
          return { ...state, items };
        }
        let idx = state.items.findIndex(function(entry) {
          return entry.fdGUID === action.options.fdGUID;
        });
        if (idx === -1) return state;
        let byGUID = { ...state.byGUID };
        delete byGUID[action.options.fdGUID];
        return {
          ...state,
          items: [...state.items.slice(0, idx), ...state.items.slice(idx + 1)],
          byGUID,
        };
      }
      case `UPDATE_${name}_REQUEST`:
        if (
          action.options.table === 'tbposlayouts' &&
          action.options.field === 'fdButtons'
        ) {
          //we will treat this as successful and worry about errors after
          let newItems = [...state.items];
          let guid = action.options.guid;
          let idx = newItems.findIndex(function(entry) {
            return entry.fdGUID === guid;
          });
          if (idx === -1) return;
          newItems[idx] = {
            ...newItems[idx],
            [action.options.field]: action.options.value,
          };
          const byGUID = {};
          for (let item of newItems) {
            byGUID[item.fdGUID] = item;
          }
          return { ...state, items: newItems, byGUID, status: 'FETCHED' };
        }
        return { ...state, status: 'UPDATING' };
      case `UPDATE_${name}_ERROR`: {
        let newItems = [...state.items];
        let guids = action.options.guid.split(',');
        if (name === 'USERS') {
          for (let guid of guids) {
            let idx = state.items.findIndex(function(entry) {
              return entry.fdUserID === guid;
            });
            if (idx === -1) continue;
            newItems[idx] = {
              ...newItems[idx],
              [action.options.field]: action.data.oldvalue,
            };
          }
          return { ...state, items: newItems, status: 'FETCHED' };
        }
        for (let guid of guids) {
          let idx = newItems.findIndex(function(entry) {
            return entry.fdGUID === guid;
          });
          if (idx === -1) continue;
          //Do NOT do this -->
          //newItems[idx][action.options.field] = action.options.oldvalue
          //this actually mutates the state, because the array is made of objects!
          //so do this -->
          newItems[idx] = {
            ...newItems[idx],
            [action.options.field]: action.options.value,
          };
        }
        const byGUID = {};
        for (let item of newItems) {
          byGUID[item.fdGUID] = item;
        }
        return { ...state, items: newItems, byGUID, status: 'FETCHED' };
      }
      case `UPDATE_${name}_SUCCESS`: {
        let newItems = [...state.items];
        if (
          action.options.table === 'tbposlayouts' &&
          action.options.field === 'fdButtons'
        ) {
          return { ...state };
        }
        if (name === 'POSPARAMS') {
          let fdPOSGuid = action.options.posGuid;
          let fdParamGUID = action.options.paramGuid;
          let fdValue = action.options.value;
          let idx = state.items.findIndex(function(entry) {
            return entry.fdPOSGuid === fdPOSGuid && entry.fdParamGUID === fdParamGUID;
          });
          if (idx === -1) {
            newItems.push({ fdParamGUID, fdPOSGuid, fdValue });
          } else {
            newItems[idx] = { fdParamGUID, fdPOSGuid, fdValue };
          }
          return { ...state, items: newItems, status: 'FETCHED' };
        }
        if (name === 'OMSPARAMS') {
          let fdOMSGuid = action.options.omsGuid;
          let fdParamGUID = action.options.paramGuid;
          let fdValue = action.options.value;
          let idx = state.items.findIndex(function(entry) {
            return entry.fdOMSGuid === fdOMSGuid && entry.fdParamGUID === fdParamGUID;
          });
          if (idx === -1) {
            newItems.push({ fdParamGUID, fdOMSGuid, fdValue });
          } else {
            newItems[idx] = { fdParamGUID, fdOMSGuid, fdValue };
          }
          return { ...state, items: newItems, status: 'FETCHED' };
        }
        if (name === 'SERVICETIMEPARAMS' || name === 'MENUBOARDPARAMS') {
          let fdDevGuid = action.options.devGuid;
          let fdParamGUID = action.options.paramGuid;
          let fdValue = action.options.value;
          let idx = state.items.findIndex(function(entry) {
            return entry.fdDevGuid === fdDevGuid && entry.fdParamGUID === fdParamGUID;
          });
          if (idx === -1) {
            newItems.push({ fdParamGUID, fdDevGuid, fdValue });
          } else {
            newItems[idx] = { fdParamGUID, fdDevGuid, fdValue };
          }
          return { ...state, items: newItems, status: 'FETCHED' };
        }

        if (name === 'USERS') {
          let guids = action.options.guid.split(',');
          for (let guid of guids) {
            let idx = state.items.findIndex(function(entry) {
              return entry.fdUserID === guid;
            });
            if (idx === -1) continue;
            newItems[idx] = {
              ...newItems[idx],
              [action.options.field]: action.options.value,
            };
            if (action.response.fields) {
              for (let field in action.response.fields) {
                newItems[idx][field] = action.response.fields[field];
              }
            }
          }
          return { ...state, items: newItems, status: 'FETCHED' };
        }
        if (name === 'USERTABLECOLUMNS') {
          let idx = newItems.findIndex((item) => item.fdTable === action.options.table);
          if (idx > -1) {
            newItems[idx] = {
              ...newItems[idx],
              fdColumns: action.options.columns,
            };
            return {
              ...state,
              items: newItems,
              status: 'FETCHED',
            };
          }
          newItems.push({
            fdTable: action.options.table,
            fdColumns: action.options.columns,
          });
          return { ...state, items: newItems, status: 'FETCHED' };
        }
        let guids;
        if (Array.isArray(action.options.guid)) {
          guids = action.options.guid.sort();
        } else {
          guids = action.options.guid.split(',');
        }
        for (let guid of guids) {
          let idx = newItems.findIndex(function(entry) {
            return entry.fdGUID === guid;
          });
          if (idx === -1) continue;
          newItems[idx] = {
            ...newItems[idx],
            [action.options.field]: action.options.value,
          };
          if (action.response.fields) {
            for (let field in action.response.fields) {
              newItems[idx][field] = action.response.fields[field];
            }
          }
        }
        if (action.options.sortBy)
          newItems.sort((a, b) => a[action.options.sortBy] - b[action.options.sortBy]);
        if (name === 'PARAMETERS') {
          const byName = {};
          for (let item of newItems) {
            byName[item.fdName] = item;
          }
          return {
            ...state,
            items: newItems,
            byName,
            status: 'FETCHED',
          };
        }
        const byGUID = {};
        for (let item of newItems) {
          byGUID[item.fdGUID] = item;
        }
        return { ...state, items: newItems, byGUID, status: 'FETCHED' };
      }
      case `LINK_UPDATE_${name}_SUCCESS`: {
        let newItems = state.items.filter(
          (row) => row[action.options.groupField] !== action.options.groupGuid,
        );
        for (let row of action.response.results) {
          newItems.push({
            fdGUID: row.guid,
            [action.options.groupField]: row.groupGuid,
            [action.options.itemField]: row.itemGuid,
          });
        }
        const byGUID = {};
        for (let item of newItems) {
          byGUID[item.fdGUID] = item;
        }
        if (
          name === 'PROMOTIONCRITERIASETITEMS' ||
          name === 'PROMOTRIGGERRESTRICTIONS' ||
          name === 'COUPONRESTRICTIONS' ||
          name === 'COUPONCRITERIASETITEMS'
        ) {
          newItems = newItems.filter((item) => !item.isNewRecord);
        }
        return { ...state, items: newItems, byGUID };
      }
      case `CHECK_PRODUCTS_${name}_SUCCESS`: {
        let newItems = state.items.filter(
          (row) => row[action.options.groupField] !== action.options.groupGuid,
        );
        for (let row of action.response.results) {
          newItems.push({
            fdGUID: row.productID,
            fdName: row.productName,
            fdVenue: row.venueAccountCode,
            fdVenueName: row.venueName,
            fdSizeID: row.sizeID,
          });
        }
        return { ...state, unavailableProducts: newItems };
      }
      default:
        return state;
    }
  };
}
